<template>
  <div class="cram-school">[ XX補習班 ]</div>
  <div class="welcome-name">Welcome, penny </div>
  <a href="#" class="change-btn" data-toggle="modal" data-target="#exampleModal">換人練習</a>

  <!-- 輸入學生姓名modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="login-modal-content">
            <h2>請輸入學生姓名</h2>
            <label><input type="text" placeholder="姓名"></label>
            <a href="index-deep.html">確 定</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 輸入學生姓名modal end -->
</template>

<script>
export default {
  name: 'AppHeader',
  props: ['title']
}
</script>

<style scoped></style>
