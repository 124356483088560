<template>
  <div class="miscellaneous-btn">
    <a href="https://www.bertedu.com/official/teachings_detail.php#6499F847-6DDC-6CB5-2984-2461E130FA92" target="_blank"
      class="login-about"><b>關於系統</b></a>
    <a href="index.html" class="ml-all-btn pn-btn-mob-size"><b><img src="images/pre-icon.png" alt=""></b></a>
    <a href="vocabulary-warm.html" class="ml-all-btn pn-btn-mob-size"><b><img src="images/next-icon.png" alt=""></b></a>
    <a href="#" class="ml-all-btn lu-btn-mob-size"><b><img src="images/logout-icon.png" alt=""></b></a>
    <a href="#" class="mob-top"><b><img src="images/top-brown-icon.png" alt=""></b></a>
  </div>

  <section class="inside-all">
    <Menu></Menu>

    <div class="inside-content">
      <ul class="mb-link">
        <li><a href="index.html" class="home">Home</a></li>
        <li><a href="index-deep.html">深耕練習</a></li>
      </ul>
      <!-- 【 平板手機補習班、學生名稱 】 -->
      <div class="mob-student-info">
        <div class="mob-cram-school">[ XX補習班 ]</div>
        <div class="mob-welcome-name">Welcome, penny </div>
        <a href="#" class="mob-change-btn" data-toggle="modal" data-target="#exampleModal">換人練習</a>
      </div>
      <!-- 【 平板手機補習班、學生名稱end 】 -->

      <MaterialSelection></MaterialSelection>
      <footer class="foot-top-mg">
        Copyright © Bert EDU. Corp. All Rights Reserved.<br>Now use Deep English Practice System 4.0 Version，Browser is
        <b>Chrome 112.0.0.0</b>
      </footer>
    </div>
  </section>
</template>

<script>
import Menu from './Menu.vue'
import MaterialSelection from '../components/MaterialSelection.vue'
export default {
  name: 'AppHeader',
  components: {
    Menu,
    MaterialSelection
  }
}
</script>

<style scoped></style>
