<template>
  <Header title="深根練習系統"></Header>
  <Content></Content>
  <LoginDialog></LoginDialog>
</template>

<script>
import Header from './layouts/Header.vue'
import Content from './layouts/Content.vue'
import LoginDialog from './layouts/LoginDialog.vue'
export default {
  name: 'App',
  components: {
    Header,
    Content,
    LoginDialog
  }
}
</script>

<style>

</style>
