<template>
  <div class="modal fade align-items-center justify-content-center" id="modal-ml-login" data-backdrop="static">

    <div class="modal-dialog modal-sm">
      <h4 class="modal-title"></h4>

      <div class="modal-content">
        <div class="modal-body">
          <!-- 【 背景可以後台更換，分桌機(建議尺寸1920x1100)、平板(建議尺寸768x1024)、手機(建議尺寸580x1260)，手機版位置不同下面有標註 】 -->
          <div class="login-page">
            <div class="login-top">
              <div class="login-logo"><img src="images/logo.png" alt="">
                <h1>深根練習系統</h1>
              </div>
              <a href="https://www.bertedu.com/official/teachings_detail.php#6499F847-6DDC-6CB5-2984-2461E130FA92"
                target="_blank" class="login-about la-com"><b>關於系統</b></a>
            </div>
            <div class="login-bg-mob"><img src="images/login-bg-mob.png" alt=""></div><!-- 【 手機版背景圖片 】 -->
            <div class="login-info">
              <h2>請輸入帳號</h2>
              <label><input type="text" placeholder="帳號"></label>
              <label><input type="text" placeholder="密碼"></label>
              <label class="li-input-a"><input type="text" placeholder="驗證碼"><b><img src="images/demo-nuber.png"
                    alt=""></b></label>
              <a href="#" class="li-btn" data-toggle="modal" data-target="#exampleModal">登 入</a>
              <a href="https://www.bertedu.com/official/teachings_detail.php#6499F847-6DDC-6CB5-2984-2461E130FA92"
                target="_blank" class="login-about la-mob"><b>關於系統</b></a>

              <!-- 輸入學生姓名modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div class="login-modal-content">
                        <h2>請輸入學生姓名</h2>
                        <label><input type="text" placeholder="姓名"></label>
                        <a href="index-deep.html">確 定</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 輸入學生姓名modal end -->
            </div>
          </div>


          <footer class="login-footer">
            Copyright © Bert EDU. Corp. All Rights Reserved.<br>Now use Deep English Practice System 4.0 Version，Browser
            is <b>Chrome 112.0.0.0</b>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import $ from 'jquery'
export default {
  mounted() {
   // $('#modal-ml-login').modal('show').addClass('d-flex');
  }
}
</script>

<style scoped>
.modal-body {
  padding: 0;
  background: url(/public/images/login-bg-tab.png) no-repeat center;
  background-size: cover;
}
.modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

#modal-ml-login {
  
}

@media (max-width:800px) {
  .modal-body {
    background: url(/public/images/login-bg-pad.png) no-repeat center top;
    background-size: cover;
  }
}

@media (max-width:580px) {
  .modal-body {
    background: none;
  }
}
</style>