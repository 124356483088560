import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'popper.js/dist/popper.min.js'

import $ from 'jquery'
window.$ = $

// mob-menu
$(document).ready(function () {
    $(".icon, .mobilenav li a").click(function () {
        $(".mobilenav").fadeToggle(500);
        $(".top-menu").toggleClass("top-animate");
        $(".mid-menu").toggleClass("mid-animate");
        $(".bottom-menu").toggleClass("bottom-animate");
        $(".menui").toggleClass("menui-act");
        $("body").toggleClass("body-mob-menu");
        $(".mobilenav").toggleClass("mobilenav-open");
    });
});

// top
$(document).ready(function () {
    $("#pagetop").hide();
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 160) {
                $('#pagetop').fadeIn("fast");
            } else {
                $('#pagetop').fadeOut("fast");
            }
        });

        $('#pagetop, .mob-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    });
});

createApp(App).mount('#app')
