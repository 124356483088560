<template>
  <div class="inside-banner">
    <!-- 【 banner建議尺寸1920x135 】 -->
    <a href="index.html"><img src="images/logo.png" class="logo" alt="">
      <h1>{{title}}</h1>
    </a><!-- 【 手機版時“深根練習系統”變成“補習班名稱” 】 -->
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  props:['title']
}
</script>

<style scoped></style>
