<template>
  <div class="select-examination">
    <label>
      <span>選擇單元：</span>
      <select name="" id="">
        <option value="0">Vocabulary</option>
        <option value="1">Sentences</option>
        <option value="2">Sentences</option>
        <option value="3">Conversation & Reading</option>
        <option value="4">Grammar</option>
        <option value="5">Listen & Say</option>
      </select>
    </label>
    <label class="se-sp-btn">
      <span>選擇練習模式：</span>
      <b><a href="#" class="act">熱身</a><a href="#">基本</a><a href="#">進階</a></b>
    </label>
    <label>
      <span>選擇題庫：</span>
      <select name="" id="">
        <option value="0">Under Way (PH1~B10)</option>
      </select>
    </label>
    <label>
      <span>選擇冊別：</span>
      <select name="" id="">
        <option value="0">Phonics-I</option>
      </select>
    </label>
    <label>
      <span>選擇課別：</span>
      <select name="" id="">
        <option value="0">Unit 1</option>
      </select>
      <a href="vocabulary-warm.html" class="send-btn">送 出</a>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MaterialSelection',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
