<template>
  <div class="inside-menu">
    
    <LoginStatus></LoginStatus>

    <ul>
      <li><a href="index-deep.html" class="act">深根練習</a></li>
      <li><a href="#">段考</a></li>
      <li><a href="#">期末檢定</a></li>
      <li><a href="#">新生檢測</a></li>
      <li><a href="#">指法練習</a></li>
      <li><a href="#">成績查詢</a></li>
    </ul>
  </div>

  <!-- mob-menu -->
  <div class="mob-menu">
    <a href="javascript:void(0)" class="icon">
      <div class="hamburger">
        <div class="menui top-menu"></div>
        <div class="menui mid-menu"></div>
        <div class="menui bottom-menu"></div>
      </div>
    </a>
    <ul class="mobilenav">
      <li class="mob-home"><a href="index.html">HOME</a></li>
      <li><a href="index-deep.html">深根練習</a></li>
      <li><a href="#">段考</a></li>
      <li><a href="#">期末檢定</a></li>
      <li><a href="#">新生檢測</a></li>
      <li><a href="#">指法練習</a></li>
      <li><a href="#">成績查詢</a></li>
    </ul>
  </div>
</template>

<script>
import LoginStatus from './LoginStatus.vue'
export default {
  name: 'AppMenu',
  components: {
    LoginStatus
  }
}
</script>

<style scoped></style>
